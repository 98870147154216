<template>
  <div class="dry-power">
    <moduleTitle title="加工能力"></moduleTitle>
    <div class="dry-power-content">
      <div class="power-content-top">
        <div class="content-top-item">
          <span class="top-item-name">中心面积(㎡)</span>
          <div class="top-item-number">{{ data.processArea }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">堆场面积(㎡)</span>
          <div class="top-item-number">{{ data.heapArea }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">日加工能力(吨)</span>
          <div class="top-item-number">{{ data.processPowerDay }}</div>
        </div>
      </div>
      <div class="power-content-bottom power_bottom">
        <div class="content-top-item">
          <span class="top-item-name">冷藏容量(㎡)</span>
          <div class="top-item-number">{{ data.coldCapacity }}</div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">设备类型</span>
          <div class="top-item-number" :title="data.deviceType">
            {{ data.deviceType }}
          </div>
        </div>
        <div class="content-top-item">
          <span class="top-item-name">加工机械</span>
          <div class="top-item-number" :title="data.deviceProcess">
            {{ data.deviceProcess }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
import { getProcesspower } from '../../../api/processApi/process';
export default {
  components: {
    moduleTitle
  },
  data () {
    return {
      data: {}
    };
  },
  mounted () {
    this.getprocesspower();
  },
  methods: {
    getprocesspower () {
      let name = `${ localStorage.getItem('address') }加工中心`;
      getProcesspower(name)
        .then(res => {
          console.log(res);
          this.data = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(292vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #7388c4, #7388c4) 10 10;
  // opacity: 0.74;

  .dry-power-content {
    margin: 0 auto;
    margin-top: companyH(90vh);
    width: companyW(422vw);
    height: companyH(130vh);

    // background-color: red;
    .power-content-top {
      width: 100%;
      height: companyH(51vh);
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      // background-color: #E69635;
      .content-top-item {
        width: companyW(160vw);
        height: companyH(57vh);

        .top-item-number {
          text-align: center;
          width: 100%;
          height: companyH(47vh);
          background-image: url(../../../assets/process/yibiaopannum.png);
          background-repeat: no-repeat;
          background-size: contain;

          font-size: companyH(16vh);
          font-family: DIN;
          font-weight: 700;
          color: #ffffff;
          line-height: companyH(40vh);
        }
      }
    }

    .power_bottom {
      width: 100%;
      height: companyH(51vh);
      display: flex;
      justify-content: left;
      justify-content: space-evenly;

      align-items: center;

      // background-color: #E69635;
      .content-top-item {
        width: companyW(160vw);
        height: companyH(57vh);
        // margin-left: companyW(15vw);

        .top-item-number {
          text-align: center;
          width: 100%;
          width: companyW(140vw);
          height: companyH(47vh);
          background-image: url(../../../assets/process/yibiaopannum.png);
          background-repeat: no-repeat;
          background-size: contain;

          font-size: companyH(16vh);
          font-family: DIN;
          font-weight: 700;
          color: #ffffff;
          line-height: companyH(40vh);

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .power-content-bottom {
      margin-top: companyH(39vh);
    }
  }
}
.top-item-name {
  margin-left: companyW(5vw);
  display: inline-block;
  margin-bottom: companyH(7vh);
  height: companyH(13vh);
  font-size: companyW(18vw);
  font-family: Source Han Sans CN;
  font-weight: 700;
  color: #eff0f1;
  line-height: companyH(17vh);
  display: flex;

  justify-content: center;
}
</style>
