<template>
  <div class="Right">
    <div class="upperright">
      <moduleTitle title="订单情况"></moduleTitle>
      <div class="orderlist">
        <div class="OrderStats" v-for="(item, index) in items" :key="index"    @click="openseeVisible(item)">
          <div class="numBox">
            {{ index + 1 }}
          </div>
          <div class="right">
            <div class="orderStatsData orderStatsData1">
              订单业主：{{ item.farmerName }}
            </div>
            <div class="orderStatsData">
              加工数量：{{ item.dryingCount }}吨
            </div>
            <!-- <div class="orderStatsData orderStatsData2">
              入场时间：{{ item.inTime }}
            </div> -->
            <div class="orderStatsData">
              预约时间：{{ item.appointmentTime }}
            </div>
          </div>
          <div class="ruchang">已入场</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="seeVisible" :show-close="false" :modal="false">
      <dv-border-box-1 :color="[ '#7388c4', '#7388c4']">
        <div class="del" @click="closexin">
          <img src="../../../../assets/drying-page/guanbi.png" class="imgBox" />
        </div>
        <div class="headText">订单详情</div>
        <div class="mainBox">
          <div class="aa">
            <p class="topBOX">订单业主</p>
            <p class="bomBOX">{{ tkData.farmerName }}</p>
          </div>
          <div class="aa">
            <p class="topBOX">联系方式</p>
            <p class="bomBOX">{{ tkData.farmerTel }}</p>
          </div>
          <div class="aa">
            <p class="topBOX">作业对象</p>
            <p class="bomBOX">{{ tkData.jobObj }}</p>
          </div>
          <div class="aa">
            <p class="topBOX">加工数量</p>
            <p class="bomBOX">{{ tkData.dryingCount }}吨</p>
          </div>
          <div class="aa">
            <p class="topBOX">要求加工时间</p>
            <p class="bomBOX">{{ tkData.appointmentEnd }}</p>
          </div>
          <div class="aa">
            <p class="topBOX">入场时间</p>
            <p class="bomBOX">{{ tkData.inTime }}</p>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
import moduleTitle from "../components/moduleTitle.vue";
import { getProcessOrderInfo } from "../../../../api/processApi/process";
export default {
  components: {
    moduleTitle,
  },
  data() {
    return {
      items: "",
      seeVisible: false,
      tkData: "",
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.getEchartData();
    // }, 500);
    this.getprocessorder();
  },
  methods: {
    // 获取加工订单接口
    getprocessorder() {
      getProcessOrderInfo()
        .then((res) => {
          console.log(res);
          this.items = res.data.orderInfo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openseeVisible(item){
      console.log(item);
     this.tkData=item
      this.seeVisible=true
    },
    closexin() {
      this.seeVisible=false
    }
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}

::v-deep .el-dialog {
  width: companyW(598vw);
  height: companyH(401vh);
  background-color: #01070c;
  border-radius: companyH(40vh);
  margin: 0 auto;
}

.dv-border-box-1 {
  position: relative;
  width: companyW(598vw);
  height: companyH(401vh);
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}

.Right {
  .upperright {
    box-sizing: border-box;
    height: 100%;
    background-color: black;
    position: relative;
    // top: 10vh;
    width: companyW(482vw);
    height: companyH(470vh);
    background: rgba(0, 20, 36, 0.4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #7388c4, #7388c4) 10 10;
    padding-left: companyW(17vw);
    padding-top: companyH(28vw);
    overflow-x: hidden;

    .orderlist {
      width: companyW(482vw);
      height: companyH(420vh);
      position: absolute;
      top: companyH(40vh);
      overflow-y: scroll;

      .OrderStats {
        box-sizing: border-box;
        display: flex;
        // flex-wrap: wrap;
        // padding: companyW(17vw) companyH(18vh);
        // background: #ffffff;
        align-items: center;

        background: rgba(255, 255, 255, 0.1);
        width: companyW(440vw);
        height: companyH(91vh);

        margin-bottom: companyH(10vh);
        color: #fff;

        .orderStatsData {
          font-size: companyW(18vw);
          font-family: Source Han Sans SC;

          color: #fff;
        }

        .orderStatsData1 {
          margin-bottom: companyH(18vh);
          margin-right: companyW(18vw);
        }

       
      }
    }
  }
}

.ruchang {
  width: companyW(65vw);
  height: companyH(35vh);
  background: rgba(30, 48, 53, 0.2);
  border: 1px solid #7286c2;
  border-radius: 2px;
  text-align: center;
  line-height: companyH(35vh);
  margin-left: companyW(10vw);
  align-self: flex-end;
}
.right {
  width:75%;
  display: flex;
  flex-wrap: wrap;
  margin-left: companyW(17vw);

}
.numBox {
  width: companyW(25vw);
  height: companyH(25vh);
  background: rgba(30, 48, 53, 0.2);
  border: 1px solid #7286c2;
  text-align: center;
  line-height: companyH(25vh);
  margin-left: companyW(10vw);
}
//弹窗样式
.headText{
  margin: 0 auto;
  width: 100%;
  height:companyH(80vh) ;
  text-align: center;
 line-height: companyH(80vh);
}
.mainBox{
  width:70%;
  height: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content:center;
  align-content: space-between;
  // background-color: pink;
  padding-left: companyW(120vw);
}

.aa{
 margin-right:companyW(40vw) ;
}
.topBOX{
  font-size:  companyW(16vw);
font-family: Source Han Sans CN;
font-weight: 400;
color: #EFF0F1;
}
.bomBOX{
  font-size:  companyW(18vw);
font-family: Source Han Sans SC;
font-weight: 400;
color: #FFFFFF;
margin-top:companyH(15vh) ;
}
.del {
  position: absolute;
  top: companyH(30vh);
  right: companyH(30vh);
  
}
.imgBox{
  width: companyW(20vw);
  height: companyH(20vh);
}
</style>
