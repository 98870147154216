<template>
  <div class="dry-service">
    <moduleTitle title="加工服务"></moduleTitle>
    <div class="dry-service-echart">
      <Echart :option="option"></Echart>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
import Echart from '../../../components/echarts/index.vue';
import option from './config/service.js';
import { getProcesspower } from '../../../api/processApi/process';
export default {
  components: {
    moduleTitle,
    Echart
  },
  data() {
    return {
      option,
    };
  },
  mounted() {
    this.getprocesspower()

  },
  methods: {
    getprocesspower() {
      let name =localStorage.getItem('address') + '加工中心'
      getProcesspower(name).then(res => {
        console.log(res,123);
        this.option.series[0].data = res.data.serverList.map(element => {
          return element.processCount;
        });
        this.option.series[1].data = res.data.serverList.map(element => {
          return element.processMyself;
        })
        this.option.series[2].data = res.data.serverList.map(element => {
          return element.processOut;
        })

        res.data.serverList.forEach((ele) => {
          this.option.xAxis.data.push({
            value: ele.year,
            textStyle: {
              color: "#ffffff",
              // fontSize:12,
            },
          });
        });
     
      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-service {
  position: relative;
  width: companyW(482vw);
  height: companyH(322vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #7388C4, #7388C4) 10 10;
  // opacity: 0.74;

  .dry-service-echart {
    margin: 0 auto;
    margin-top: companyH(40vh);
    width: companyW(400vw);
    height: companyH(260vh);
  }
}
</style>
