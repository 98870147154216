<template>
  <div class="middle">
    <div class="middle-nav">
      <navList :Option="navList" />
    </div>
    <storageAndDry />
    <div class="middle-tray">
    </div>
  </div>
</template>

<script>
import navList from '../components/navList.vue';
import storageAndDry from './storageAndDry.vue';

export default {
  components: {
    navList,
    storageAndDry,
  },
  data() {
    return {

    };
  },

};

</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.middle {
  position: relative;
  width: companyW(916vw);
  height: companyH(978vh);
  overflow: hidden;

  .middle-nav {
    width: companyW(716vw);
    height: companyH(65vh);
    margin: 0 auto;
    margin-top: companyH(44vh);
  }

  .middle-tray {
    position: absolute;
    top: companyH(360vh);
    left: companyW(-75vw);
    // margin-left: companyW(-70vw);
    width: companyW(1092vw);
    height: companyH(500vh);
    background: url('../../../../assets/process/组\ 7.png') no-repeat center;
    z-index: 99999;
    opacity: 0.8;
  }
}
</style>
