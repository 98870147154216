import * as echarts from 'echarts';
export default{
  backgroundColor:'transparent',
  tooltip: {
    show:false,
    trigger: 'axis',
  },
  grid: {
    left: '20',
    right: '20',
    bottom: '0',
    top:'30%',
    containLabel: true
  },
  legend: {
    data: [ '加工总量', '自我加工量', '对外服务量' ],
    right:0,
    top:20,
    textStyle: {
      color: '#fff',
      fontSize:16

    },
    itemWidth: 12,
    itemHeight: 10,
    // itemGap: 35
  },
  xAxis: {
    type: 'category',
    data: [
      // {
      //   value:2020,
      //   textStyle:{
      //     color:'#ffffff',
      //     // fontSize:12
      //   }
      // },
      // {
      //   value:2021,
      //   textStyle:{
      //     color:'#ffffff',
      //     // fontSize:12
      //   }
      // },
      // {
      //   value:2022,
      //   textStyle:{
      //     color:'#ffffff',
      //     // fontSize:12
      //   }
      // },
      // {
      //   value:2023,
      //   textStyle:{
      //     color:'#ffffff',
      //     // fontSize:12
      //   }
      // }
    ],
    axisLine: {
      show:true,
      lineStyle: {
        color: '#1A5575',
        fontSize: 14

      }
    },
    axisLabel: {
      textStyle: {
        fontFamily: 'Microsoft YaHei',
        fontSize: 16

      }
    },
    axisTick :{
      show:false
    },
  },

  yAxis: {
    type: 'value',
    show:true,
    axisLabel:{
      show:false
    },
    splitLine:{
      show:true,
      lineStyle:{
        color:'#1A5575',
        type:'dashed'
      }
    }
  },
  series: [    
    //series[0].data 
    {
      name: '加工总量',
      type: 'bar',
      barWidth: '10%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ {
            offset: 0,
            color: '#E68C04'
          }, {
            offset: 1,
            color: '#734403'
          } ]),
        },
      },
      data: [ 400, 400, 300, 300 ]
    },
    {
      name: '自我加工量',
      type: 'bar',
      barWidth: '10%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ {
            offset: 0,
            color: '#2BA7E0'
          }, {
            offset: 1,
            color: '#244C6B'
          } ])
        }

      },
      data: [ 400, 500, 500, 500 ]
    },
    {
      name: '对外服务量',
      type: 'bar',
      barWidth: '10%',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ {
            offset: 0,
            color: '#00C08D'
          }, {
            offset: 1,
            color: '#026C41'
          } ]),
        }
      },
      data: [ 400, 600, 700, 700 ]
    } ]
};

