<template>
  <div class="dyr-service">
    <Title class="dyr-service-title" njTitle="top-title" typeOf="process" :title="address+'数字加工'"></Title>
    <div class="rightBgc anniu1" @click="jumpshouye">
      <img src="../../../assets/njfwimgs/shouye.png" class="imgBox" />
      <p>首页</p>
    </div>
    <a class="rightBgc anniu2" href="http://nsfwgl.ifasun.com" target="_blank">
      <img src="../../../assets/njfwimgs/houtai.png" class="imgBox" />
      <p>后台</p>
    </a>
    <div class="rightBgc anniu3" @click="toback">
      <img src="../../../assets/njfwimgs/quanping.png" class="imgBox" />
      <p>全屏</p>
    </div>
    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <Power></Power>
        <Service class="dyr-service-service"></Service>
        <LastYear class="dyr-service-service"></LastYear>
      </div>
      <div class="dyr-service-middle">
        <Mddle />
      </div>
      <div class="dyr-service-right">
        <Right />
        <Right_fin class="right_fin" />
      </div>
    </div>
    <bottomNav class="middle-bottomNav" />
  </div>
</template>

<script>
import Title from './components/title.vue';
import Power from './power.vue';
import Service from './service.vue';
import LastYear from './lastYear.vue';
import Mddle from './middle/index.vue';
import bottomNav from './components/bottomNav.vue';
import Right from './right/index.vue';
import Right_fin from './right/right_fin.vue';

export default {
  components: {
    Title,
    Power,
    Service,
    LastYear,
    Mddle,
    bottomNav,
    Right,
    Right_fin,
  },
  data() {
    return {
      address:''
    };
  },
  created() {
    this.address = localStorage.getItem('address')
  },
  methods: {
    jumpshouye () {
      this.$router.push({
        path: '/'
      });
    },
    jumphoutai () {
      this.$router.push('http://nsfwgl.ifasun.com');
    },
    toback () {
      document.documentElement.requestFullscreen();
    }

  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../../assets/process/all.png);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;



  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);

      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);

      .right_fin {
        margin-top: companyH(21vh);
      }
    }
  }

  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(632vw);
    background-image: url(../../../assets/process/bottomNav.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.rightBgc {
  width: companyW(90vw);
  height: companyH(30vh);
  background-color: #4a515a;
  border-radius: companyW(5vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 companyW(10vw) 0 companyW(10vw);

  .imgBox {
    width: companyW(24vw);
    height: companyH(24vh);
  }
  p {
    font-size: companyH(18vh);
    color: #fff;
  }
}
.anniu1 {
  position: absolute;
  left: companyW(70vw);
  top: companyH(8vh);
}
.anniu2 {
  position: absolute;
  left: companyW(194vw);
  top: companyH(8vh);
}
.anniu3 {
  position: absolute;
  right: companyW(70vw);
  top: companyH(8vh);
}
</style>
