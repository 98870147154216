<template>
  <div class="dry-lastYear">
    <moduleTitle title="对比上年情况"></moduleTitle>
    <div class="dry-lastYear-content">
      <div class="lastYear-content-echart">
        <Echart :option="dbsnOption"></Echart>
      </div>
    </div>

    <div class="lastYear-content-top">
      <div class="content-top-item">
        <div>
          <div class="little-box"></div>
          <span class="top-item-name">本年加工量</span>
        </div>
        <div class="top-item-number">{{ processchartData.list[0].process_count }}吨</div>
      </div>

      <div class="content-top-item">
        <div>
          <div class="little-box-red"></div>
          <span class="top-item-name">上年加工量</span>
        </div>
        <div class="top-item-number-red">{{ processchartData.list[1].process_count }}吨</div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from './components/moduleTitle.vue';
import Echart from '../../../components/echarts/index.vue';
import Option from './config/lastyear.js';
import * as ybmock from '@/views/processAll/process/config/ybpChart.js';
import { getProcesspower } from '../../../api/processApi/process';
export default {
  components: {
    moduleTitle,
    Echart
  },
  data() {
    return {
      Option,
      ...ybmock,
      processchartData: {},
    };
  },
  mounted() {
    this.getprocesspower()
    // this.value = 80
    console.log(this.value);
  },
  methods: {
    getprocesspower() {
      let name =localStorage.getItem('address') + '加工中心'
      getProcesspower(name).then(res => {
        console.log(res);
        this.processchartData = res.data
        this.value = (res.data.list[0].process_count / res.data.list[1].process_count) * 100
        this.dbsnOption.title[0].text = `{a|${((res.data.list[0].process_count / res.data.list[1].process_count) * 100).toFixed(0)}}{b|%} `;
        if (this.value >= 100) {
          this.value = 99
          this.dbsnOption.series[0].axisLine = {
            show: true,
            lineStyle: {
              color: [
                [0, '#bccdff'],
                [this.value / 100, 'rgba(32,187,252,0.15)'],
                [1, '#bccdff'],
              ],
              width: 8,
            },
          }
        } else {
          this.dbsnOption.series[0].axisLine = {
            show: true,
            lineStyle: {
              color: [
                [0, '#bccdff'],
                [this.value / 100, 'rgba(32,187,252,0.15)'],
                [1, '#bccdff'],
              ],
              width: 8,
            },
          }
        }

        console.log(this.value);
      }).catch(err => {
        console.log(err);
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-lastYear {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: companyW(482vw);
  height: companyH(322vh);

  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #7388C4, #7388C4) 10 10;

  .dry-lastYear-content {
    margin: 0 auto;
    margin-top: companyH(50vh);
    width: companyW(400vw);
    height: companyH(260vh);
    margin-left: companyH(30vh) ;


    .lastYear-content-echart {
      width: companyH(207vh);
      height: companyH(207vh);
      background-image: url(../../../assets/process/yibiaopan.png);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .lastYear-content-top {
    margin-top: companyH(93vh);
    margin-right: companyW(50vw);
    width: companyW(217vw);
    height: companyH(161vh);

    // margin-top: ;
    .content-top-item {
      width: companyW(150vw);
      // height: companyH(60vh);

      .top-item-name {

        margin-left: companyW(5vw);
        display: inline-block;
        margin-bottom: companyH(7vh);
        height: companyH(13vh);
        font-size: companyH(12vh);
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #eff0f1;
        line-height: companyH(17vh);
      }

      .little-box {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #e69635;
      }

      .little-box-red {
        display: inline-block;
        width: companyW(6vw);
        height: companyH(8vh);
        background-color: #e65635;
      }

      .top-item-number {
        text-align: center;
        margin-left: companyW(7vw);
        width: 100%;
        height: companyH(47vh);
        background-image: url(../../../assets/process/yibiaopannum.png);
        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #ffffff;
        line-height: companyH(47vh);
      }

      .top-item-number-red {


        margin-left: companyW(7vw);
        text-align: center;
        width: 100%;
        height: companyH(47vh);
        background-image: url(../../../assets/process/yibiaopannum-o.png);

        background-size: cover;
        font-size: companyH(16vh);
        font-family: DIN;
        font-weight: 700;
        color: #ffffff;
        line-height: companyH(47vh);
      }
    }
  }
}

.dry-lastYear::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 20, 36, 0.4);

  width: 100%;
  height: 100%;
  z-index: -1;
  /* 将伪元素放在父元素底部 */
  // opacity: 0.74;

}
</style>
